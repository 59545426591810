import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Dropdown, Menu, Button } from 'antd';
import { useHistory } from 'react-router';
import { DownOutlined } from '@ant-design/icons';

import {
  BUSINESSES_COLUMNS,
  BUSINESS_MANAGERS_UPDATE_KEYS,
  BUSINESS_STATUS
} from 'constants/Businesses';
import { downloadAsXlsx, formatAddress, formatOptions } from 'utils';
import { isUserAuthorized } from 'utils/helpers';
import { openModal } from 'utils/modal';
import aclMatrix, { ACCOUNTANT, SUPER_ADMIN } from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { exportBusinessConfigSheet } from 'services/wallet';
import {
  allowEditBankDetails,
  deleteBusiness,
  exportBusinessBankInfoXlsx,
  exportBusinessFawryInfoXlsx,
  getSalesUsers,
  searchBusinesses,
  updateBusiness
} from 'services/business';

import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';
import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container';
import { notify } from 'components/Notify/Notify';
import Banner from 'components/Banner/Banner';
import InvalidatedBusinessModal from 'components/Businesses/InvalidatedBusinessModal/InvalidatedBusinessModal';
import BusinessesSearch from '../BusinessesSearch/BusinessesSearch';
import BRDropdownModal from 'components/BRDropdownModal/BRDropdownModal';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';
import AddNoteModal from '../AddNoteModal/AddNoteModal';

import { ReactComponent as Actions } from 'assets/bosta-icons/More-Actions.svg';

import './BusinessesListing.less';

const BusinessesListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchBusinessesValues, setSearchBusinessValues] = useState({});
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [show2FABanner, setShow2FABanner] = useState(false);
  const [allSalesUsers, setAllSalesUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [bulkActionsVisible, setBulkActionsVisible] = useState(false);

  const refreshTable = useRef();

  const intl = useIntl();
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem('userInfo'));

  const isAllowEditBankDetails = isUserAuthorized(aclMatrix.BUSINESSES, [
    ACL_MATRIX.DISABLE_BANK_UPDATE_LIMIT
  ]);

  useEffect(() => {
    if (user.twoFA && user.twoFA?.isTwoFAEnabled) {
      if (
        !user.twoFA?.isTOTPTwoFASetupCompleted &&
        !user.twoFA?.isEmailTwoFASetupCompleted &&
        !user.twoFA?.isSMSTwoFASetupCompleted
      ) {
        setShow2FABanner(true);
      }
    }
  }, []);

  const handleBulkPromotionClick = () => {
    history.push('/businesses/bulk/promotions');
  };

  const handleBulkUpdatePricingTierClick = () => {
    history.push('/businesses/bulk/pricing/update');
  };

  const handleBulkUploadClick = () => {
    history.push('/businesses/bulk');
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const handleRefreshTable = () => {
    setSelectedRowsIds([]);
    refreshTable.current({
      pageNumber: page,
      filterValues: searchBusinessesValues
    });
  };

  const getAllSalesUser = async () => {
    try {
      const { salesUsers } = await getSalesUsers();
      return salesUsers;
    } catch (error) {
      notify(error.message);
    }
  };

  const getSalesUsersData = async () => {
    let salesUsers;

    if (!allSalesUsers.length) {
      const salesUsersList = await getAllSalesUser();
      salesUsers = formatOptions(
        '_id',
        'profile.firstName,profile.lastName',
        salesUsersList || []
      );
      setAllSalesUsers(salesUsers);
    } else {
      salesUsers = allSalesUsers;
    }

    return salesUsers;
  };

  const fetchBusinesses = async ({ page, filterValues }) => {
    try {
      const payload = {
        ...(filterValues ? { ...filterValues } : { ...searchBusinessesValues }),
        pageId: page - 1
      };

      const { result, count } = await searchBusinesses(payload);
      const salesUsers = await getSalesUsersData();

      setCount(count);
      setPage(page);

      return {
        list: formatBusinesses({ businesses: result, salesUsers }),
        total: count
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const handleExportBusinessBankDetailsClick = async () => {
    setIsLoading(true);
    try {
      const res = await exportBusinessBankInfoXlsx({
        ...(selectedRowsIds.length
          ? { businessId: selectedRowsIds.toString() }
          : { ...searchBusinessesValues, pageId: undefined })
      });
      downloadAsXlsx(res, `Bank-Info.xlsx`);
      notify(
        intl.formatMessage({
          id: `business_details.export_bank_info_success`
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleExportBusinessFawryDetailsClick = async () => {
    setIsLoading(true);
    try {
      await exportBusinessFawryInfoXlsx({
        ...(selectedRowsIds.length
          ? { businessId: selectedRowsIds.toString() }
          : { ...searchBusinessesValues, pageId: undefined })
      });

      notify(
        intl.formatMessage({
          id: `business_details.export_fawry_info_success`
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeBusinessStatus = ({ businessId, status, restPayload }) => {
    const payload = {
      status,
      ...restPayload
    };
    handleUpdateBusiness({ businessId, payload });
  };

  const handleAssignManagers = ({
    businessId,
    isAssignToSalesManager,
    salesUsers
  }) => {
    openModal(BRDropdownModal, {
      title: intl.formatMessage({
        id: `businesses.actions.${
          isAssignToSalesManager
            ? 'assign_sales_manager'
            : 'assign_account_manager'
        }.title`
      }),
      confirmBtnText: intl.formatMessage({ id: 'common.ok' }),
      dropdownPlaceholder: intl.formatMessage({
        id: `businesses.actions.${
          isAssignToSalesManager
            ? 'assign_sales_manager'
            : 'assign_account_manager'
        }.placeholder`
      }),
      dropdownData: salesUsers,
      onConfirm: (userId) => {
        const payload = {
          [isAssignToSalesManager
            ? BUSINESS_MANAGERS_UPDATE_KEYS.SALES_MANAGER
            : BUSINESS_MANAGERS_UPDATE_KEYS.ACCOUNT_MANAGER]: userId
        };
        handleUpdateBusiness({ businessId, payload });
      }
    });
  };

  const handleInvalidateBusinessClick = (businessId) => {
    openModal(InvalidatedBusinessModal, {
      handleChangeBusinessStatus,
      businessId
    });
  };

  const handleAddNoteClick = (businessId) => {
    openModal(AddNoteModal, {
      onSuccess: (values) =>
        handleUpdateBusiness({ businessId, payload: values })
    });
  };

  const handleUpdateBusiness = async ({ businessId, payload }) => {
    try {
      await updateBusiness(businessId, payload);
      handleRefreshTable();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleDeleteBusiness = async (businessId) => {
    try {
      await deleteBusiness(businessId);
      handleRefreshTable();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleAllowEditBank = async ({ businessId }) => {
    setIsLoading(true);
    try {
      await allowEditBankDetails({ businessId });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const MenuItem = ({ business, salesUsers }) => {
    return (
      <Menu>
        <Menu.Item
          key="0"
          disabled={
            !isUserAuthorized(aclMatrix.BUSINESSES, [
              ACL_MATRIX.BUSINESS_ASSIGN_ACCOUNT_MANAGER
            ])
          }
          onClick={() => {
            handleAssignManagers({ businessId: business._id, salesUsers });
          }}
        >
          {intl.formatMessage({
            id: 'businesses.actions.assign_account_manager.title'
          })}
        </Menu.Item>
        <Menu.Item
          key="1"
          disabled={
            !isUserAuthorized(aclMatrix.BUSINESSES, [
              ACL_MATRIX.BUSINESS_ASSIGN_SALES_MANAGER
            ])
          }
          onClick={() => {
            handleAssignManagers({
              businessId: business._id,
              isAssignToSalesManager: true,
              salesUsers
            });
          }}
        >
          {intl.formatMessage({
            id: 'businesses.actions.assign_sales_manager.title'
          })}
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            handleAddNoteClick(business._id);
          }}
        >
          {intl.formatMessage({ id: 'businesses.actions.add_note' })}
        </Menu.Item>
        <Menu.Item
          key="3"
          disabled={
            !isUserAuthorized(aclMatrix.BUSINESSES, [
              ACL_MATRIX.BUSINESS_VALIDATE_STATUS
            ])
          }
          onClick={() =>
            business.status?.value === BUSINESS_STATUS.INVALID
              ? handleChangeBusinessStatus({
                  businessId: business._id,
                  status: BUSINESS_STATUS.VALID
                })
              : handleInvalidateBusinessClick(business._id)
          }
        >
          {intl.formatMessage({
            id: `businesses.status.${
              business.status?.value === BUSINESS_STATUS.INVALID
                ? 'valid'
                : 'invalid'
            }`
          })}
        </Menu.Item>
        {isAllowEditBankDetails && (
          <Menu.Item
            key="4"
            onClick={() => {
              handleAllowEditBank({ businessId: business._id });
            }}
          >
            {intl.formatMessage({
              id: 'businesses.actions.allow_edit_bank'
            })}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const actionDropdown = ({ business, salesUsers }) => (
    <Dropdown overlay={MenuItem({ business, salesUsers })} trigger={['click']}>
      <BRButton
        type="table-actions"
        className="button-sm"
        suffixIcon={<Actions />}
      />
    </Dropdown>
  );

  const handleRowSelection = (selectedIds) => {
    setSelectedRowsIds(selectedIds);
  };

  const formatBusinesses = ({ businesses, salesUsers }) => {
    return businesses.map((itm) => ({
      key: itm._id,
      businessName: (
        <Link
          to={`/businesses/${itm._id}/details`}
          className="br-business-listing__name"
        >
          {itm.name.trim().length ? itm.name : itm._id}
        </Link>
      ),
      address: itm?.address?.length
        ? formatAddress(itm.address[0])
        : intl.formatMessage({ id: 'common.empty_field' }),
      registeredAt: dayjs(new Date(itm.createdAt)).tz().format('LLL'),
      status:
        itm?.status !== undefined
          ? itm?.status.value
          : intl.formatMessage({ id: 'common.empty_field' }),
      accountManger: itm.accountManager
        ? itm.accountManager.name
        : intl.formatMessage({ id: 'common.empty_field' }),
      salesManager: itm.salesManager
        ? itm.salesManager.name
        : intl.formatMessage({ id: 'common.empty_field' }),
      Actions: actionDropdown({ business: itm, salesUsers })
    }));
  };

  const handleOnSubmit = (values) => {
    setSearchBusinessValues(values);
    refreshTable.current({ pageNumber: 1, filterValues: values });
  };

  const handleExportAllBusinessConfig = async () => {
    setIsLoading(true);
    try {
      const { message } = await exportBusinessConfigSheet();
      notify(message, 'success');
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const bulkActionsList = () => {
    return (
      <>
        <Menu onClick={() => setBulkActionsVisible(false)}>
          {isUserAuthorized(
            [ACCOUNTANT, SUPER_ADMIN],
            [ACL_MATRIX.BUSINESS_EXPORT_BANK_DETAILS]
          ) && (
            <>
              <Menu.Item key="0" onClick={handleBulkPromotionClick}>
                {intl.formatMessage({
                  id: 'businesses.search_filters.promotion_upload'
                })}
              </Menu.Item>
              <Menu.Item
                key="1"
                onClick={handleExportBusinessBankDetailsClick}
                disabled={isLoading}
              >
                {intl.formatMessage({
                  id: 'businesses.search_filters.export_bank_details'
                })}
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={handleExportBusinessFawryDetailsClick}
                disabled={isLoading}
              >
                {intl.formatMessage({
                  id: 'businesses.search_filters.export_fawry_details'
                })}
              </Menu.Item>
            </>
          )}

          {isUserAuthorized(
            [ACCOUNTANT, SUPER_ADMIN],
            [ACL_MATRIX.BUSINESS_EXPORT, ACL_MATRIX.BUSINESS_UPDATE_WALLET]
          ) &&
            isUserAuthorized(
              [ACCOUNTANT, SUPER_ADMIN],
              [ACL_MATRIX.BUSINESS_UPDATE_WALLET]
            ) && (
              <>
                <Menu.Item key="3" onClick={handleBulkUpdatePricingTierClick}>
                  {intl.formatMessage({
                    id: 'businesses.search_filters.bulk_update_pricing_tier'
                  })}
                </Menu.Item>
                <Menu.Item key="4" onClick={handleBulkUploadClick}>
                  {intl.formatMessage({
                    id: 'businesses.search_filters.bulk_upload'
                  })}
                </Menu.Item>
                <Menu.Item
                  key="5"
                  onClick={handleExportAllBusinessConfig}
                  disabled={isLoading}
                >
                  {intl.formatMessage({
                    id: 'businesses.search_filters.export_all_businesses'
                  })}
                </Menu.Item>
              </>
            )}
        </Menu>
      </>
    );
  };

  const renderTableAction = () => (
    <Dropdown
      visible={bulkActionsVisible}
      onVisibleChange={setBulkActionsVisible}
      overlay={bulkActionsList}
      trigger={['click']}
      getPopupContainer={(trigger) => trigger.parentElement}
      destroyPopupOnHide
      placement="bottomRight"
    >
      <div className="br-business-listing__bulk-actions-dropdown">
        <Button
          loading={isLoading}
          className="br-business-listing__bulk-actions-dropdown-button"
          onClick={() => setBulkActionsVisible(!bulkActionsVisible)}
          disabled={isLoading}
        >
          <span>{intl.formatMessage({ id: 'common.bulk_actions' })}</span>
          <DownOutlined />
        </Button>
      </div>
    </Dropdown>
  );

  return (
    <Container
      header={
        <BRHeader title={intl.formatMessage({ id: 'businesses.title' })} />
      }
      content={
        <>
          {show2FABanner && (
            <Banner
              title={intl.formatMessage(
                {
                  id: 'settings.security.banner.title'
                },
                {
                  click_here: (
                    <a href={'/settings/security'} rel="noreferrer">
                      {intl.formatMessage({
                        id: `settings.security.banner.click_here`
                      })}
                    </a>
                  )
                }
              )}
              parentClassName="br-businesses__2fa-banner"
            />
          )}
          <BusinessesSearch
            handleExportBusinessBankDetailsClick={
              handleExportBusinessBankDetailsClick
            }
            handleExportAllBusinessConfig={handleExportAllBusinessConfig}
            handleOnSubmit={handleOnSubmit}
            isLoading={isLoading}
          />
          <BRTable
            className="br-businesses__table"
            title={intl.formatMessage(
              { id: 'businesses.table_columns.title' },
              {
                count
              }
            )}
            allowSelection
            handleSelectedRows={handleRowSelection}
            shareMethods={acceptMethods}
            scroll={{ x: 1300, y: 600 }}
            columns={BUSINESSES_COLUMNS}
            listFunction={fetchBusinesses}
            showFilter={false}
            tableActions={renderTableAction}
          />
        </>
      }
    />
  );
};

export default BusinessesListing;
