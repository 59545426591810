import http from 'utils/http';
import { headerDownload } from 'utils';

export const fetchBusinesses = (payload) => {
  return http.get(`/businesses/valid-business-names`, payload);
};

export const fetchFXFBusinesses = () => {
  return http.get(`/businesses/valid-fulfillment-activated-names`);
};

export const fetchBusinessPickupLocations = (businessId) => {
  return http.get(`/businesses/${businessId}/pickup-locations`);
};

export const putBusinessPhone = async (businessId, phone) => {
  const res = await http.put(`/businesses/${businessId}/phone`, phone);
  return res;
};

export const searchBusinesses = (payload) => {
  return http.get('/businesses/search', payload);
};

export const getBusiness = (id, options = {}) => {
  return http.get(`/businesses/${id}`, undefined, options);
};

export const updateBusiness = (id, payload) => {
  return http.put(`/businesses/${id}`, payload);
};

export const deleteBusiness = (businessId) => {
  return http.delete(`/businesses/${businessId}`);
};

export const updateBusinessConfigurations = (id, payload) => {
  return http.put(`/businesses/${id}/feature-configurations`, payload);
};

export const updateFulfillmentActivation = (id, payload) => {
  return http.put(`/businesses/${id}/fulfillment-activation`, payload);
};

export const exportBusinessBankInfoXlsx = async (data) => {
  return await http.get(`/businesses/bank-info-csv`, data, headerDownload);
};

export const exportBusinessFawryInfoXlsx = async (data) => {
  return await http.get(`businesses/fawry-info/export`, data);
};

export const sandboxApprove = async (id, payload) => {
  return await http.put(`/businesses/${id}/sandbox/approve`, payload);
};

export const editTeamMemeberInfoValidation = async (payload) => {
  return await http.post(`/users/change-contact-otp`, payload);
};

export const confirmTeamMemeberInfoValidation = async (payload) => {
  return await http.post(`/users/confirm-contact-otp`, payload);
};

export const editTeamMemeberInfoVerification = async (payload) => {
  return await http.post(`/users/change-contact`, payload);
};

export const confirmTeamMemeberInfoVerification = async (payload) => {
  return await http.post(`/users/verify_new_contact`, payload);
};

export const getBusinessTeamMembers = async (id, payload) => {
  return await http.get(`/businesses/${id}/users`, payload);
};

export const getBusinessFlyersInfo = (payload) => {
  return http.get(`/businesses/businessFlyersInfo`, payload);
};

export const addExtraFlyersQuota = (id, payload) => {
  return http.post(`/businesses/addExtraFlyersQuota?businessId=${id}`, payload);
};

export const editOperationsSettings = (businessId, payload) => {
  return http.put(`businesses/${businessId}/operations-settings`, payload);
};

export const addDefaultReturnContact = (id, payload) => {
  return http.post(`/businesses/${id}/addDefaultReturnContact`, payload);
};

export const editSendAutomatedCalls = (id, payload) => {
  return http.put(`/businesses/${id}/bot-call-activation`, payload);
};

export const generateAPIKey = (payload) => {
  return http.post(`/apikey`, payload);
};

export const getSllrData = (id) => {
  return http.get(`/sllr/${id}/sllr-info`);
};

export const getBasicllrInfo = (id, options) => {
  return http.get(`/sllr/${id}/basic-sllr-info`, undefined, options);
};

export const downloadLegalDocuments = ({ businessId, payload }) => {
  return http.get(
    `/businesses/${businessId}/legal-docs`,
    payload,
    headerDownload
  );
};

export const getSubAccounts = ({ businessId }) => {
  return http.get(`/businesses/${businessId}/sub-accounts`);
};

export const getInternationalBusinesses = () => {
  return http.get('/businesses/valid-international-activated-names');
};

export const getSalesUsers = () => {
  return http.get('/users/sales-users');
};

export const uploadBulkUpdateBusinessPricingTierSheet = (payload) => {
  return http.post('/businesses/updateBusinessTier', payload);
};

export const getLegalDocUploadLink = ({ businessId, payload }) => {
  return http.get(`/businesses/${businessId}/getLegalDocUploadLink`, payload);
};

export const allowEditBankDetails = (payload) => {
  return http.post('/businesses/disable-bank-info-update-rate', payload);
};

export const getBusinessContactPersons = ({ businessId }) => {
  return http.get(`/businesses/${businessId}/pickup-locations`);
};
